$white: #ffffff !default;
$black: #000000 !default;

body {
	overflow-x: hidden;
}
img{
	max-width: 100%;
}

// =========================================
// Flexbox

// Flexbox
.flexbox {
	display: flex;
	flex-flow: row wrap;
}

// Justify-content
.align-right {
	justify-content: flex-end;
}
.align-center {
	justify-content: center;
}
.align-justify {
	justify-content: space-between;
}
.align-spaced {
	justify-content: space-around;
}
.align-top {
	align-items: flex-start;
}

//align-items
.align-self-top {
	align-self: flex-start;
}
.align-bottom {
	align-items: flex-end;
}
.align-self-bottom {
	align-self: flex-end;
}
.align-middle {
	align-items: center;
}
.align-self-middle {
	align-self: center;
}
.align-stretch {
	align-items: stretch;
}
.align-self-stretch {
	align-self: stretch;
}
.align-center-middle {
	justify-content: center;
	align-items: center;
	align-content: center;
}


// =========================================
// Helper
.hide{
	display: none !important;
}


// =========================================
// Loading
.loader{
	width: 100vw;
	height: 100vh;
	background-color:#406083;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	span{
		display: none;
	}
	transition:0.5s;
	.loader_center {
		background: url('../images/loading.gif') 50% 40% no-repeat;
		background-size: 150px;
		width: 100vw;
		height: 100vh;
		@media #{$small-only} {
			background-size: 80px;
		}
	}
	&.close {
		top: 100vh;
		transition:0.8s;
	}
}


//---------------------------------------------------------------- Media Query
@media #{$xsmall-only} {}

@media #{$small-up} {}
@media #{$small-only} {}
@media #{$small-down} {}

@media #{$medium-up} {}
@media #{$medium-only} {}
@media #{$medium-down} {}

@media #{$large-up} {}
@media #{$large-only} {}
@media #{$large-down} {}

@media #{$xlarge-up} {}
@media #{$xlarge-only} {}
@media #{$xlarge-down} {}

@media #{$xxlarge-up} {}
@media #{$xxlarge-only} {}
@media #{$xxlarge-down} {}