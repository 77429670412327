// --------------------------------------------------------
// Main Style
// --------------------------------------------------------
@charset "UTF-8";
@import "layout/main_style";

// --------------------------------------------------------
/* Font Style*/
// --------------------------------------------------------
@import "layout/font";

// --------------------------------------------------------
/* Vendor Style*/
// --------------------------------------------------------
@import "vendor/normalize";
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700|Roboto:300,400,500,700,900');
// @import "vendor/font/icons";

// --------------------------------------------------------
/* Page Style*/
// --------------------------------------------------------
@import "layout/layout";
select::-ms-expand { display:none; opacity:0; }

::-webkit-full-page-media { width:100vw; background:#fff; }
::-webkit-full-screen {
    background-color: white;
    z-index: 2147483647; }
iframe::-webkit-media-controls { background:#fff; }
iframe::-webkit-media-controls-toggle-closed-captions-button { background-color:#fff; }
body:-webkit-full-page-media {
    background-color: rgb(255, 255, 255);}

::-webkit-scrollbar {
    width:10px; background-color:rgba(0,0,0,0); opacity:0;
}
::-webkit-scrollbar-button:vertical:decrement {
    background-image:url(""); background-repeat: no-repeat; opacity:0;
}
::-webkit-scrollbar-button:vertical:increment {
    background-image:url(""); background-repeat:no-repeat; opacity:1;
}
::-webkit-scrollbar-thumb {
    background-color:#304673; opacity:0;
}
::-webkit-scrollbar-thumb:vertical {
    height:10px; background-color:#304673; opacity:0.8; -webkit-border-radius:3px;
}
::-webkit-scrollbar-thumb:horizontal {
    width:10px; background-color:#304673; opacity:0.8; -webkit-border-radius:3px;
}
::-webkit-scrollbar-corner {
    background-color: #304673; opacity: 0;
}
::-ms-scrollbar {
    width: 10px; background-color: rgba(0,0,0,0);
}
::-ms-scrollbar-button:vertical:decrement {
    background-image:url(""); background-repeat:no-repeat;
}
::-ms-scrollbar-button:vertical:increment {
    background-image:url(""); background-repeat:no-repeat;
}
::-ms-scrollbar-thumb {
    background-color: #304673; opacity: 0;
}
::-ms-scrollbar-thumb:vertical {
    height: 10px;background-color: #304673;opacity: 0.8;-ms-border-radius: 3px;
}
::-ms-scrollbar-thumb:horizontal {
    width: 10px;background-color: #304673;opacity: 0.8;-ms-border-radius: 3px;
}
::-ms-scrollbar-corner {
    background-color:#304673; opacity:0.8;
}

html { 
    width:100vw; 
    position:relative; 
    overflow-x:hidden; 
    // fastclick solved
    touch-action: manipulation;
}
body { overflow-x:hidden; width:100vw; position:relative; opacity:0; animation:opac 0.5s 0.5s forwards; -webkit-animation:opac 0.5s 0.5s forwards; background:#fff; } 
select::-ms-expand { display:none; }
input::-ms-expand { display:none; }
a{ text-decoration:none; }
p, ol, select, input { margin:0; padding:0; font-family:"Noto Sans CJK TC", FontAwesome, "LiHei Pro", 儷黑體, sans-serif, "Microsoft JhengHei"; }
p.small, span.small, ol.small, select.small, input.small { font-size:15px; }
p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:16px; }
p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:17px; }
p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:19px; }
p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:25px; }
p.big, span.big, ol.big, select.big, input.big { font-size:35px; }
p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:42px; }
p.weight { font-weight:600; }

.header { width:100vw; height:62px; border-bottom:1px solid rgba(0,0,0,0.5); background:rgba(255,255,255,0.8); position:fixed; top:0; left:0; z-index:999;
    @media #{$medium-down} { background:#fff; height:auto; } 
    p { letter-spacing:0.5px; }
    .header_center { width:calc(100% - 300px); margin:0 auto; overflow:hidden;
        @media #{$xlarge-down} { width:calc(100% - 100px); } //1281~1440
        @media #{$medium-down} { width:calc(100% - 40px); }
        &.pc { display:block;  
            @media #{$medium-down} { display:none; }
        }
        &.mo { display:none; 
            @media #{$medium-down} { display:block; }
        }
        .header_logo { width:185px; margin:10px 0 8px 0; float:left; cursor:pointer; 
            @media #{$medium-down} { width:39px; margin:5px 0 3px 0; } 
            .logo1 { display:none; }
            .logo2 { display:block; }
        }
        .menu { width:30px; height:30px; position:fixed; right:20px; top:8px;
            .line { width:100%; height:6px; background:#fff; margin:5px 0; 
                &.one { transform:rotate(0deg); transition:0.4s; }
                &.two { transition:0.4s; }
                &.three { transform:rotate(0deg); transition:0.4s; }
                .line_center { width:calc(100% - 2px); height:calc(100% - 2px); margin:1px; background:#273859; }
            }
            &.click {
                .line{
                    &.one { transform:rotate(-45deg) translate(-2px,14px); transition:0.4s; }
                    &.two { opacity:0; transition:0.4s; }
                    &.three { transform:rotate(45deg) translate(-2.5px,-12px); transition:0.4s; }
                }
            }
        }
        .header_page_area { width:calc(100% - 230px); float:right; overflow:hidden;
            @media #{$medium-down} { display:none; }
            .page_botton { float:right; text-align:center; color:#555; padding:13px 15px 18px 15px; margin:8px 0 0 0; transition:0.3s; cursor:pointer; position:relative;
                .bottom_line { position:absolute; width:0px; height:2px; background:#109af5; left:0; bottom:0; transition:0.4s; }
                &.log_botton { background:#e52b2b; border-radius:40px; border:none; padding:8px 30px; margin:13px 0 13px 15px;
                    @media #{$small-down} { background:#000; } 
                }
                &:hover { color:#109af5; transition:0.3s; .bottom_line { width:100%; transition:0.4s; } }
            }
        }
    }
}
.menu_botton { width:100vw; position:fixed; top:-150%; transition:0.5s; left:0; z-index:998;
    .header_page_area { height:100vh; background:#6189a8; padding:30px 0 0 0;
        .page_center { height:calc(100vh - 50px - 70px); overflow-y:scroll; }
        .page_botton { width:calc(100% - 40px); margin:0 auto; padding:15px 0; border-bottom:1px solid #273859; color:#fff; display:block;
            &.log_botton { width:calc(100% - 100px); background:#e52b2b; border:none; border-radius:40px; margin:20px 0 0 50px; }
        }
        input { margin:0 0 50px 0; }
    }
    &.click { top:50px; transition:0.5s; }
}
#footer { position:relative; }
.gotop { width:40px; height:40px; position:absolute; right:calc((100% - 1440px) / 2); top:-70px; }
.footer_bar { width:100%; background:#273859;
    .footer_top { width:100%;
        .footer_center { width:calc(100% - 40px); max-width:980px; margin:0 auto; overflow:hidden;
            @media #{$medium-down} { width:calc(100% - 40px); }
            .pagebotton_area { width:100%; overflow:hidden; position:relative; padding:15px 0;
                .page_bottom { width:calc(100% / 2 - 20px); color:#ccc; transition:0.3s; letter-spacing:0.5px; font-weight:600; cursor:pointer;
                    &.left { float:left; text-align:right; }
                    &.right { float:right; text-align:left; }
                    &:hover { color:#fff; transition:0.3s; }
                } 
                .center_line { position:absolute; width:1px; height:20px; left:calc(50% - 0.5px); top:calc(50% - 10px); background:#fff; }
            }
            .footer_article { overflow:hidden; padding:0 0 15px 0; position:relative;
                .article_left { float:none; margin:25px 0 0 0;
                    @media #{$small-down} { width:100%; }
                    .article_word { color:#fff; letter-spacing:0.5px; margin:0 0 10px 0; line-height:170%;
                        @media #{$small-down} { text-align:center; }
                        &.pc { display:block;  
                            @media #{$small-down} { display:none; }
                        }
                        &.mo { display:none; line-height:170%;
                            @media #{$small-down} { display:block; }
                        }
                    }
                }
                .qrcode { width:100px; float:right; vertical-align:bottom; vertical-align:bottom; img { width:100%; } 
                          position:absolute; right:0; top:calc(50% - 50px);
                          @media #{$small-down} { position:relative; transform:translateX(-11px); } //640-
                    &.pc { display:block;  
                        @media #{$small-down} { display:none; }
                    }
                    &.mo { display:none; float:none; margin:60px auto 0 auto;
                        @media #{$small-down} { display:block; }
                    }
                }
                .article_right { width:245px; float:none; overflow:hidden; vertical-align:bottom; margin:0 auto; transform: translateX(16px);
                    @media #{$small-down} { width:235px; margin:0 auto; float:none; }
                    .icon_box { float:left; vertical-align:bottom; vertical-align:bottom; width:30px; height:30px; margin:0px 15px 0 0; 
                        :last-child { margin:0px 0 0 0; 
                            @media #{$small-down} { margin:0px 0px 0 0; }
                        }
                        @media #{$small-down} { margin:0px 15px 0 0; }
                        .cls-1 { fill:#949494; transition:0.3s; cursor:pointer; 
                            @media #{$medium-down} { fill:#fff; }
                        }
                        &:hover {
                            .cls-1 { fill:#fff; transition:0.3s; }
                        }
                    }
                    .icon_word { float:left; vertical-align:bottom; vertical-align:text-bottom; color:#fff; margin:7px 15px 0 0; 
                        // @media #{$small-down} { margin:15px 15px 0 0; }
                    }
                }
            }
        }
    }
    .footer_bottom { background:#37496d; padding:8px 0; text-align:center; color:#bbb;
        p { width:100%; max-width:calc(100% - 40px); margin:0 20px; }
        &.pc { display:block;  
            @media #{$small-down} { display:none; }
        }
        &.mo { display:none; line-height:150%;
            @media #{$small-down} { display:block; }
        }
    }
}
.content_title { padding:25px 0; letter-spacing:1px; overflow:hidden;
    &.w { color:#fff; letter-spacing:1px; }
    &.b { color:#273859; letter-spacing:1px; }
    &.center { text-align:center; }
    &.left { text-align:left; }
    &.right { text-align:right; }
    .left_title { float:left; }
    .right_pageicon { float:right; overflow:hidden;
        .icon_box { width:50px; height:50px; float:right; cursor:pointer;
            .page_svg{ fill:#707070; transition:0.3s; }
            &:hover {
                .page_svg{ fill:#e52b2b; transition:0.3s; }
            }
            &.click {
                .page_svg{ fill:#e52b2b; transition:0.3s; }
            }
        }
    }
}
.pagetop_content{ position:relative; background-attachment:fixed; background-size:cover; background-position:center center; margin:63px 0 0 0;
    @media #{$large-down} { background-attachment:scroll; }
    @media #{$medium-down} { margin:50px 0 0 0; }
    .circle { width:360px; height:360px; position:absolute; left:calc(50% - 180px); top:calc(50% - 180px); z-index:8; 
        img { width:100%; height:100%; } 
        @media #{$medium-down} { width:240px; height:240px; left:calc(50% - 120px); top:calc(50% - 120px); }
    }
    .page_name { width:100%; padding:180px 0; text-align:center; color:#fff; position:relative; z-index:9;
        @media #{$medium-down} { padding:100px 0; }
        .name_c { letter-spacing:3px; font-weight:600; p { font-style:oblique; } }
        .name_e { p { font-family: 'Josefin Sans', sans-serif; font-weight:300; } }
    }  
    &.news { background-image:url(#{$images-path}pagetop/top_bg_news.jpg); }
    &.courst { background-image:url(#{$images-path}pagetop/top_bg_courst.jpg); }
    &.plan { height:400px; 
        @media #{"screen and (max-width:959px)"} { height:250px;  }
        @media #{$small-down} { height:auto; } //640-
        video { width:100vw; height:100%; background:#fff; transform:scale(1.35); } 
        img { width:100%; }
        &.pc { display:block; 
            @media #{$small-down} { display:none; } //640-
        }
        &.mo { display:none; 
            @media #{$small-down} { display:block; } //640-
        }
    }
    &.lecture { background-image:url(#{$images-path}pagetop/top_bg_lecturer.jpg); }
    &.convention { background-image:url(#{$images-path}pagetop/top_bg_convention.jpg); }
}
.ie { .pagetop_content {
    background-attachment:scroll; 
} }
.content_center { width:1440px; margin:0 auto; 
    &.news { width:calc(1440px + 40px); 
        .content_title { width:calc(100% - 20px); margin:0 auto; }
    }
    &.tab { width:calc(1440px - 60px); overflow:inherit; position:relative; }
    // &.tab_mo { display:none; }
    .content_title { color:#304673; font-weight:500; }
}
.owl-dots { overflow:hidden; height:16px; position:absolute; padding:5px 0; left:150px; bottom:30px;
    @media #{$small-down} { bottom:10px; } //640-
    .owl-dot { width:40px; height:2px; float:left; margin:0px 4px 0 4px; background:rgba(255,255,255,0.7); border:2px solid rgba(255,255,255,0); transition:0.3s;
        &.active { background:none; border:2px solid #e52b2b; background:#e52b2b; width:40px; height:2px; margin:0px 4px 0 4px; transition:0.3s; 
            @media #{$small-down} { width:30px; height:1px; } //640-
        }
        @media #{$small-down} { width:30px; height:1px; } //640-
    }
    @media #{$medium-down} { left:20px; }
}


.btn { display:block; border-radius:3px; letter-spacing: 1px; text-transform: uppercase; text-decoration: none; position: relative; overflow: hidden; -webkit-transition: all 250ms ease; transition: all 250ms ease; 
    width:160px; margin:30px auto 0 auto; border:2px solid #e12b2b; color:#e12b2b; border-radius:30px; text-align:center;  p { padding:8px 0px; } }
.btn:hover { color:white; }
.btn:hover:after { -webkit-animation-name: bgin; animation-name: bgin; -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
.btn:after { content: ''; z-index: 2; width: 150%; height: 100%; display: block; position: absolute; top: 0; left: 50%; -webkit-animation-name: bgout; animation-name: bgout; -webkit-animation-duration: 400ms; animation-duration: 400ms; -webkit-transform: skew(-45deg) translate(-150%); transform: skew(-45deg) translate(-150%); }
.btn p { position:relative; z-index:9; }

.btn.rad:hover { color:#fff; border:2px solid #e52b2b; }
.btn-rad { border:2px solid #e12b2b; color:#e12b2b; }
.btn-rad:after { background-color:#e12b2b; }

.btn.white:hover { color:#535353; }
.btn.white { color:#fff; border:2px solid #fff; color: #fff; }
.btn-white:after { background-color: #fff; }

.btn.orange:hover { color:#fff; border:2px solid #e52b2b; }
.btn-orange { border:2px solid #e52b2b; color: #e52b2b; float:right; margin:14px 0 0 10px; width:120px; p { padding:6px 0px; } }
.btn-orange:after { background-color: #e52b2b; }

@keyframes opac {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes con1_cir {
    0% { opacity:0; }
    100% { opacity:1; }
}

/*頁碼*/
.pageLinks { height:auto; margin:30px auto 0px auto; overflow:hidden; position:relative; z-index:90; }
.pageLinks .center_links { overflow:hidden; }
.pageLinks span.prev a { color:#aaa; font-weight:200; font-size:28px; }
.pageLinks span.next a { color:#aaa; font-weight:200; font-size:28px; }
.pageLinks a { display:block; width:40px; height:40px; line-height:40px; text-align:center; float:left; font-size:15px; color:#aaa; font-weight:400; margin:0 0px; transition:0.3s; cursor:pointer; }
.pageLinks a:hover { color:#fff; transition:0.3s; }
.pageLinks a svg { width:40px; height:40px; }
.pageLinks a svg polygon { fill:#f08a38;stroke:#f08a38;stroke-miterlimit:10; transition:0.3s; }
.pageLinks a:hover svg polygon { fill:#fff; stroke:#fff; transition:0.3s; }
.pageLinks strong { width:40px; height:40px; line-height:40px; text-align:center; font-size:15px; float:left; color:#fff; font-weight:400; border-left:0px; margin:0 0px; ; }

.pageLinks strong p { width:25px; height:25px; margin:7.5px 0 0 7.5px; line-height:170%; border-radius:25px; background:#f08a38; color:#fff; }
.pageLinks p { color:#aaa; }


//輪播平板跑版問題修復
.owl-carousel { 
    .owl-stage{
        display: flex;
        flex-direction: row;
    }
}
//--end
 

// @media #{$xsmall-only} {} //0~480

// @media #{$small-up} {} //481+
// @media #{$small-only} {} //480~640
// @media #{$small-down} {} //640-

// @media #{$medium-up} {} //641+
// @media #{$medium-only} {} //641~1024
// @media #{$medium-down} {} //1024-

// @media #{$large-up} {} //1281+
// @media #{$large-only} {} //1025~1280
// @media #{$large-down} {} //1280-

// @media #{$xlarge-up} {} //1281+
// @media #{$xlarge-only} {} //1281~1440
// @media #{$xlarge-down} {} //1440-

// @media #{$xxlarge-up} {} //1281+
// @media #{$xxlarge-only} {} //1441~1920
// @media #{$xxlarge-down} {} //1920-

@media screen and (max-width:1680px) and (min-width:1501px){
}
@media screen and (max-width:1500px) and (min-width:1350px){    
    p.small, span.small, ol.small, select.small, input.small { font-size:15px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:16px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:17px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:19px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:25px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:35px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:42px; }
    .content_center { width:1180px;
        &.news { width:calc(1180px + 20px); }
        &.small { width:calc(1180px - 300px); }
        &.tab { width:calc(1180px - 60px);  }
    }
    .gotop { right:calc((100% - 1180px) / 2); top:-70px; }
}
@media screen and (max-width:1349px) and (min-width:1024px){
    p.small, span.small, ol.small, select.small, input.small { font-size:14px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:15px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:16px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:17px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:22px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:32px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:38px; }
    .content_center { width:980px;
        &.news { width:calc(980px + 20px); }
        &.small { width:calc(980px - 300px); }
        &.tab { width:calc(980px - 60px);  }
    }
    .gotop { right:calc((100% - 980px) / 2); top:-60px; }
}
@media screen and (max-width:1023px) and (min-width: 960px){
    p.small, span.small, ol.small, select.small, input.small { font-size:14px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:15px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:16px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:17px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:19px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:26px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:35px; }
    .content_center { width:920px;
        &.news { width:calc(920px + 20px); }
        &.small { width:calc(920px - 300px); }
        &.tab { width:calc(920px - 60px);  }
    }
    .gotop { right:calc((100% - 920px) / 2); top:-60px; }
} 
@media screen and (max-width:959px) and (min-width: 700px){
    p.small, span.small, ol.small, select.small, input.small { font-size:14px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:14px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:16px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:16px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:18px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:28px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:28px; }
    .content_center { width:calc(100% - 40px);
        &.news { width:calc(100% - 40px); }
        &.small { width:calc(100% - 40px); }
        &.tab { width:calc(100% - 60px); float:right; display:block;}
        // &.tab_mo { width:calc(100% - 60px); float:right; display:block; }
    }
    .gotop { right:20px; top:-60px; }
}
@media screen and (max-width:700px) and (min-width:451px) {
    p.small, span.small, ol.small, select.small, input.small { font-size:13px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:13px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:16px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:16px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:18px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:30px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:32px; }
    .content_center { width:calc(100% - 40px);
        &.news { width:calc(100% - 40px); }
        &.small { width:calc(100% - 40px); }
        &.tab { width:calc(100% - 60px); float:right; display:block; }
        // &.tab_mo { width:calc(100% - 60px); float:right; display:block; }
    }
    .gotop { right:20px; top:-60px; }
}
@media screen and (max-width: 450px) {
    p.small, span.small, ol.small, select.small, input.small { font-size:13px; }
    p.middle_center, span.middle_center, ol.middle_center, select.middle_center, input.middle_center { font-size:13px; }
    p.middle, span.middle, ol.middle, select.middle, input.middle {font-size:16px; }
    p.big_middle, span.big_middle, ol.big_middle, select.big_middle, input.big_middle { font-size:16px; }
    p.big_small, span.big_small, ol.big_small, select.big_small, input.big_small { font-size:18px; }
    p.big, span.big, ol.big, select.big, input.big { font-size:30px; }
    p.huge, span.huge, ol.huge, select.huge, input.huge { font-size:26px; }
    .content_center { width:calc(100% - 40px);
        &.news { width:calc(100% - 40px); }
        &.small { width:calc(100% - 40px); }
        &.tab { width: auto; margin: 0 24px; overflow: visible; }
        // &.tab_mo { width:calc(100% - 40px); float:right; display:block; }
        .content_title {
            font-weight:600;
        }
    }
    .gotop { right:20px; top:-60px; }
}


@-webkit-keyframes bgin {
    from { -webkit-transform: skew(-45deg) translate(-150%); transform: skew(-45deg) translate(-150%); }
    to { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
}
@keyframes bgin {
    from { -webkit-transform: skew(-45deg) translate(-150%); transform: skew(-45deg) translate(-150%); }
    to { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
}
@-webkit-keyframes bgout {
    from { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
    to { -webkit-transform: skew(-45deg) translate(50%); transform: skew(-45deg) translate(50%); }
}
@keyframes bgout {
    from { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
    to { -webkit-transform: skew(-45deg) translate(50%); transform: skew(-45deg) translate(50%); }
}